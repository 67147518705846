import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cityhall1 from './cityhall1.jpg'

class Text extends Component {
    render() {
        return (
            <div>
                <div className='title_head'>
                    <div>
                        Локація №6: Міська Ратуша
                    </div>
                    <Link to='/terebovlia/start'>
                        <div>Початок квесту тут...</div>
                    </Link>
                </div>
                <div>
                    А зараз ви знаходитесь  біля міської ратуші Теребовлі. Проте це вже друга ратуша міста. За річкою, до якої ми з вами дійдемо згодом,
                    розпочинається старе місто, і перша ратуша, ймовірно, знаходилась там, де зараз знаходиться парк Шевченка (до якого ми також із вами згодом вирушимо)
                    <img className='img_content' src={cityhall1} />
                </div>
                <ul>
                    Цікавинки:
                    <li>
                        Історія теперішньої ратуші починається лишень в XIX столітті, з 1869 року. Саме тоді була збудована ця двоповерхова будівля.
                    </li>
                    <li>
                        На першому поверсі ще до 1890 року була розміщена чотирикласова школа. Пізніше вона мала 6 класів.
                        А у міжвоєнний період було уже 7 класів. На другому поверсі засідав магістрат.
                    </li>
                    <li>
                        Цікаво те, що перша вежа на ратуші була збудована значно пізніше, лишень на початку 20 століття.
                        Це була дерев’яна вежа з оглядовим майданчиком та чотирма годинниками. Також у вежі був дзвін.
                    </li>
                    <li>
                        Але, на превеликий жаль, в липні 1917 року, в ході Першої світової війни, російські війська, відступаючи з міста,
                        підпалили вежу і вона впала на магістрат, який також згорів.
                    </li>
                    <li>
                        Після Першої світової війни магістрат відновили, але без вежі, і лишень 1938 року вежа була відновлена, але вже в іншому вигляді.
                        До речі, стіни вежі були зробленні з очеретини і поштукатурені товщиною  всього 5 см і лише по кутах були бетонні балки.
                        Тому декілька років тому міська рада взялась за її реконструкцію.
                    </li>
                </ul>
                <div>
                    А зараз ми з вами вирушаємо до костелу оо. Кармелітів, який знаходиться зовсім поряд. Вам необхідно просто перейти дорогу.
                </div>
                <div>
                    <div>
                        Координати:
                    </div>
                    <div>
                        49°18'00.1"N 25°41'16.4"E
                    </div>
                </div>
            </div>
        )
    }
}

export default Text;