import React, { Component } from 'react';
import Text from './tex';

class WallOfHistory extends Component {
    render() {
        return (
            <div>
                <Text />
                <div>
                    Завдання:
                </div>
                <div>
                    Коли будете йти до наступної локації, вам необхідно буде знайти проміжну точку - це Теребовлянський коледж культури і мистецтв.
                    Там ви знайдете пам’ятник людини, в чиєму образі ви найвірогідніше були колись або будете в майбутньому. Місцеві ж називають цей пам’ятник просто Славком.
                    Хто ж цей загадковий хлопчина? Зацікавили?
                </div>
                <div>
                    Тоді хутчіш вирушаємо далі:
                </div>
                <div>
                <div>
                    Координати коледжу:
                </div>
                <div>
                    49°17'56.8"N 25°41'10.2"E
                </div>
                </div>
            </div>
        );
    }
}

export default WallOfHistory;