import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sokil1 from './sokil1.jpg';
import sokil2 from './sokil2.jpg';
import sokil3 from './sokil3.jpg';
import sokil4 from './sokil4.jpg';
import sokil5 from './sokil5.jpg';

class WhatisIT extends Component {
    render() {
        return (
            <div>
                <div className='title_head'>
                    <div>
                        Локація №4: Будинок товариства Сокіл
                    </div>
                    <Link to='/terebovlia/start'>
                        <div>Початок квесту тут...</div>
                    </Link>
                </div>
                <ul>
                    Цікавинки:
                    <li>
                        Збудували цей будинок в 1905 році, всього за пів року, для польського спортивно-гімнастичного товариства «Сокіл».
                        В Першу світову війну російські війська облаштували тут церкву. В міжвоєнний період тут знову були спортивні зали.
                        <img className='img_content' src={sokil1} />
                    </li>
                    <li>
                        Після війни в цьому місці розташовувався кінотеатр. А з 1964 року і донині тут знову дитяча спортивна школа.
                        <img className='img_content' src={sokil2} />
                    </li>
                    <li>
                        Майже навпроти спортивної школи, за деревами та пам’ятником Іванові Франку, є гарний двоповерховий особняк,
                        який довгий час займала центральна бібліотека. Ймовірно, саме в цьому будинку, було фотоательє відомого в
                        міжвоєнний період фотографа Бруно Шниліхта. Саме завдяки його фотографіям ми можемо багато цікавого довідатись
                        про Теребовлю.
                        <img className='img_content' src={sokil3} />
                    </li>
                    <li>
                        А праворуч від Сокола  ви побачите велику будівлю Районної держадміністрації.
                        <img className='img_content' src={sokil4} />
                    </li>
                    <li>
                        Колись на цьому місці був парк з великим обеліском, на якому по боках були пам’ятні таблички Софії Хшановській
                        та Бартошу Гловацькому. Вінчав обеліск орел з розправленими крилами, символ Польщі, який знищила радянська влада,
                        щойно прийшла в Теребовлю, а після війни таблички та обеліск зняли і 1954 року на цей постамент встановили пам’ятник Шевченкові,
                        який трохи згодом перенесли на його сучасне місце.
                        <img className='img_content' src={sokil5} />
                    </li>
                </ul>
                <div>
                    А зараз ви можете побачити там пам’ятник героям Теребовлі, що віддали своє життя за Україну у війні на сході.
                    Обов’язково зверніть увагу на цей пам’ятник, адже українці мають знати своїх героїв.
                </div>
                <div>
                    <div>
                        А по цьому фото спробуйте віднайти народний дім, колишній будинок Каси «Поміч» – це найбільша та найгарніша кам’яниця
                        Теребовлі. Збудували її в 1910-1912 роках. У вересні 1939 тут містились служби НКВС, а у 1941 було розміщено жандармерію.
                    </div>

                </div>

            </div>
        );
    }
}

export default WhatisIT;