import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Text extends Component {
    render() {
        return (
            <div>
                <div className='title_head'>
                    <div>
                        Локація №8: Стіна Історій
                    </div>
                    <Link to='/terebovlia/start'>
                        <div>Початок квесту тут...</div>
                    </Link>
                </div>
                <div>
                    Зараз ви знаходитесь біля муралу, який був намальованний  командою "Пізнай Своє" та місцевими волонтерами.
                </div>
                <div>
                    На стіні ви бачите людей, які торгують на ринку. Ця тема тут неспроста, оскільки, як ми уже неодноразово повторювались,
                    торгівля для теребовлянців колись мала важливе значення.
                </div>
                <div>
                    Й не раз жінкам доводилось брати зі собою на ринок своїх дітей, оскільки не було з ким залишити їх.
                </div>
                <div>
                    Власне наступна точка, куди ми з вами відправимось, і є місцем, де раніше, ще до Першої світової війни знаходився так званий великий ринок. А що це саме за місце, ви дізнаєтесь трішки згодом.
                </div>
                <div>
                    А зараз ви можете зробити яскраві фото на фоні муралу та вирушати далі.
                </div>
            </div>
        )
    }
}

export default Text;