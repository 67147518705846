import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class WailingWall extends Component {
    render() {
        return (
            <div>
                <div className='title_head'>
                    <div>
                        Локація №11: Стіна плачу
                    </div>
                    <Link to='/terebovlia/start'>
                        <div>Початок квесту тут...</div>
                    </Link>
                </div>

                <div>
                    «Силуети у вікнах – матері із дітьми, чоловіки і жінки – сусіди, родичі, земляки… Жити заради живих, заради тих, хто поряд із нами сьогодні – найважливіше»
                </div>
                <ul>
                    Цікавинки:
                    <li>
                        Малюнок на цій стіні символізує життя, яке вирує в будинку сьогодні. Дерево, що проростає на ґрунті минулого і дає надію майбутнім поколінням.
                        Це настінне полотно сьогодні привертає  увагу до цього історичного місця: для когось - це пам'ять про минуле, а когось – змушує замислитись над глибшим змістом.
                    </li>
                    <li>
                        Щороку 17 вересня теребовлянці збираються біля будинку на вулиці Гжицького, щоб згадати загиблих від рук НКВС-КДБ земляків-патріотів.
                    </li>
                    <li>
                        На цьому місці було встановлено меморіальну плиту, що позначила місце розстрілу націоналістів,
                        їх рідних, усіх, кого підозрювали в причетності до українського підпільного руху, в націоналістичних настроях.
                    </li>
                    <li>
                        Відтепер «Стіна плачу», як називають її між собою теребовлянці, розрослася «Деревом життя», що символізує працю, зростання заради майбутнього,
                        заради рідних і близьких, що живуть поряд з нами. Мурал став символом того, що життя триває, і в цьому будинку (як і в інших) також народжуються діти та
                        збираються сім’ї.
                    </li>
                </ul>
                <div>
                    Роботу на стіні виконала художниця Ніна Гриців.  Над розробкою ескізу працювала Ірена Слободян.
                </div>
                <div>
                    А ми вирушаємо далі - у місце, де вирує історія. Теребовлянський замок- це справжня середньовічна твердиня на вершині гори,
                    звідки відкривається прекрасний вид на місто.
                </div>
                <div>
                    <div>
                        Координати:
                    </div>
                    <div>
                        49°17'54.6"N 25°41'03.0"E
                    </div>
                </div>
            </div>
        );
    }
}

export default WailingWall;