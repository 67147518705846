import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FirebaseImageUpload from '../../../../components/Firebaseimageupload/firebaseimageupload';

interface TextState {
    ishidden: boolean
}

interface TextProps {

}

class Text extends Component<TextProps, TextState> {
    constructor(props: TextProps) {
        super(props)
        this.state = {
            ishidden: true
        }
        this.updateData = this.updateData.bind(this);
    }

    updateData(value: boolean) {
        this.setState({
            ishidden: value
        })
    }

    render() {
        return (
            <div>
                <div className='title_head'>
                    <div>
                        Локація №12: Теребовлянський замок
                    </div>
                    <Link to='/terebovlia/start'>
                        <div>Початок квесту тут...</div>
                    </Link>
                </div>
                <div>
                    Історія теребовлянських укріплень сягає своїми коренями ще  часів княжих міжусобиць, коли Теребовлею володів нащадок великого князя
                    Ярослава Мудрого - Василько Ростиславович. Уже тоді на Замковій Горі, що здіймається над місцевістю на 50 м у висоту, було засноване давньоруське городище,
                    у плані трикутної форми, що зі сходу було захищене земляними валами, залишки яких сьогодні можна зустріти за території Замкової гори.
                </div>
                <ul>
                    Цікавинки:
                    <li>
                        Про ці перші укріплення Теребовлі, на жаль, немає жодних літописних згадок. Відомо лише те, що за часів окупації українських земель
                        польським королем Казимиром Великим, у центрі Старого міста, на високій горі «стояла дерев’яна кріпость на кам’яному фундаменті».
                    </li>
                    <li>
                        Так у 1341 році, після переходу Теребовлі під владу Польщі в південній частині Замкової гори був закладений фундамент
                        для майбутніх кам’яних укріплень. Тоді ж у скалі був висічений колодязь  глибиною близько 45м (з кінця XVII ст. була засипана
                        і не функціонувала до ХІХ ст.). Проте ця споруда Казимира Великого проіснувала лише до кінця XІV століття, згодом – була зруйнована і спалена.
                    </li>
                    <li>
                        Наступна згадка про замок, новий чи перебудований із залишків старих укріплень, датується 1534 роком. Краківський каштелян
                        Анджей Тинчинський за власні кошти відновлює кам’яні мури Теребовлянського замку. У цей час його брат Станіслав займає посаду
                        Теребовлянського старости. Новий замок стояв на сторожі міста близько століття, а залишки цих давніх укріплень виявлені археологами
                        під час розкопок на території замку у 2012р.
                    </li>
                    <li>
                        Сучасного вигляду Теребовлянський замок набув за часів старости Олександра Балабана. Причиною будівництва міцної твердині
                        у Теребовлі стали часті татарські набіги (близько 15 разів на рік), в результаті яких чисельність жителів міста скоротилась вдвічі.
                        Завершено будівництво нового замку у 1632-33рр.
                    </li>
                    <li>
                        Біля замку ви побачите пам’ятник Анні Дороті Хшановській, більш відомій, як Софія Хшановська. Ця мужня жінка змогла втримати
                        оборону Теребовлянського замку від турецької навали
                    </li>
                    <li>
                        Її називають Теребовлянською Жанною  д'Арк - Анна Хшановська, яка пригрозила своєму чоловікові, приставивши кинджал йому до горла.
                        Цим змінила долю Теребовлянського замку восени 1675 р.
                    </li>
                    <li>
                        У 1675 році до Теребовлі прийшла звістка, що західною Україною рухається величезне 10 000 військо Ібрагіма Шішмана, який на той
                        час захоплював усі замки,  які траплялися йому по дорозі. На той час очолював гарнізон замку військовий командир Ян Самуель Хшановський.
                        Коли замок  оточила турецька армія, він зібрав нараду, щоб вирішити чи здати замок без бою і вивісити білий прапор, чи все ж таки захищати
                        замок до останньої краплі крові. Самуель Хшановський розуміючи, що на території самого замку знаходиться не більше 200 воїнів й протистояти
                        проти величезного 10 000 війська неможливо, вирішив проголосувати за те, щоб здати замок. Але Анна  переконала чоловіка продовжити оборону
                        і той з палашем в руках змусив переляканих повернутись на їх позиції. Коли ж і її чоловік вже зневірився в обороні, Анна вихопила  у нього
                        кинджал, приставила йому до горла і пригрозила, що якщо він здасть без бою у руки ворогам замок та його жителів, то загине від рук своєї власної
                        дружини. Є також версія, що вона пригрозила підпалити порохові запаси і висадити замок в повітря у випадку капітуляції.
                    </li>
                    <li>
                        9 днів Софія Хшановська очолювала оборону Теребовлянського замку і змогла дочекатися підмоги з боку польського короля Яна Собеського
                        й з того часу стала однією із найвидатніших жінок міста Теребовлі
                    </li>
                </ul>
                <div>
                    <div>
                        І останнє завдання для вас сьогодні: зробіть сельфі на фоні замку і закиньте сюди, яке б відображало ваші емоції під час проходження квесту.
                    </div>
                    <FirebaseImageUpload _path={'TerebovliaCastle'} updateData={this.updateData} />
                    <div hidden={this.state.ishidden}>
                        <div>
                            Позаду замку є чудовий парк, де ви можете прогулятись та насолодитись  природою
                        </div>
                        <div>
                            На цьому наш квест закінчується.
                        </div>
                        <div>
                            Проте лише Теребовлею мальовничі місця цього краю не закінчуються. Рекомендуємо вам обов’язково відвідати туристичний центр,
                            де ви зможете більш детальніше дізнатись про історичні місця в околицях Теребовлі й навіть обрати для себе екскурсію, або ж узяти на прокат велосипед
                        </div>
                        <div>
                            Також у туристичному центрі ви маєте нагоду пройти дуже цікаві майстер-класи за доступними цінами, й виготовити сувенір власноруч:
                            декоративний розпис посуду, випалювання на дерев’яній основі, друк зображення на дереві, або ж навіть виготовлення виробів на гончарному
                            крузі - усе це ви зможете спробувати там.
                        </div>
                        <div>
                            Тож не гайте часу та хутчіш вирушайте туди.
                        </div>
                        <div>
                            <div>
                                Координати:
                        </div>
                            <div>
                                49°18'04.2"N 25°41'32.7"E
                        </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Text;