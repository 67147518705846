import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Text extends Component {
    render() {
        return (
            <div>
                <div>
                    Молодці. А зараз трішки про місце, біля якого ви зараз знаходитесь.
                </div>
                <ul>
                    Цікавинки:
                    <li>
                        Орден кармелітів бере свій початок з Палестини, а його колискою є гора Кармель, що височіє над Середземним морем.
                        У 1604 р. група кармелітів вирушили з Риму до Персії, обравши свій шлях через слов’янські землі.
                    </li>
                    <li>
                        Перші представники ордену кармелітів з’явились у Теребовлі у 1617 р., їм була подарована земля для будівництва храму.
                        1635 р. вважається роком завершення будівництва кам’яного храму.
                    </li>
                    <li>
                        Кармелітська обитель – одна із найстаріших святинь на Теребовлянщині, яка побудована в оборонному стилі.
                        Костел оточений кам’яним муром із чотирма двоярусними вежами. У вежах є бійниці для ведення вогню не тільки з ручної зброї, а й невеликих гармат.
                    </li>
                    <li>
                        При вході на територію ви можете побачити дзвіницю, яка була добудована в XV столітті.
                    </li>
                    <li>
                        Є письмові згадки про те, що в оборонні мури у XVII ст. були вмуровані пам’ятні таблиці: таблиця померлих з 1588 р.
                        від зарази морової (холери), а також надгробна таблиця Самюеля Дульського (одного із жертводавців храму) 1749 р. Доля цих пам’яток
                        невідома і до сьогодні.
                    </li>
                    <li>
                        В XVII столітті під час частих нападів турків і татар були знищені дві вежі головного фасаду храму і пізніше не відновлювались.
                        Теперішні вежі з церковними куполами з’явились вже у 1990-их роках.
                    </li>
                    <li>
                        На території церкви ви можете побачити криницю, яка колись автентично закінчувалась на рівні із землею й була прихованою.
                        На випадок, коли турки брали місто й добирались до костелу, монахи спускались в криницю й не доходячи до рівня води, мали там нішу і сховок.
                    </li>
                    <li>
                        До початку Першої світової війни у костелі знаходилась ікона Матері Божої Скарлежної, оздоблена дорогоцінними металами та камінням.
                        У 1945р., по закінченню Другої світової війни, Теребовлю залишили останні кармеліти, забравши із собою храмове майно та ікону Матері Божої,
                        яка сьогодні перебуває у костелі святої Катерини у м. Гданськ (Республіка Польща).
                    </li>
                    <li>
                        У радянський час храм був пристосований для потреб фабрики ялинкових прикрас, а у 1987 р. у костелі трапилась пожежа,
                        яка  повністю знищила інтер’єр святині. У 1991 р. костел передано Українській автокефальній православній церкві й сьогодні храм носить ім’я св.Володимира.
                        Стараннями декана о. Володимира Якубишина храм відреставровано, відновлено дві втрачені вежі, а увесь комплекс споруд у даний час є пам’яткою
                        архітектури державного значення.
                    </li>
                </ul>
                <div>
                    Це була остання сакральна споруда у нашому квесті, а зараз ми з вами  рухаємось у сторону старого міста і трішки заглибимось у мистецтво.
                    Наступна наша точка - це мурал, що був намальований в рамках нашого проєку «Пізнай Своє». Коли йтимете до наступної локації, зверніть увагу на міст,
                    яким будете проходити. Його побудували зі залізобетону ще на початку XX століття ( а ось його автентична світлина). Що цікаво, половина моста в липні 1917 року,
                    тоді ж коли спалили ратушу, була підірвана і впала в річку, а відновили міст тільки наприкінці 1920-их років.
                </div>
                <div>
                    Мурал знаходиться за адресою вулиця М.Паращука, 1
                </div>
                <div>
                    <div>
                        Координати:
                    </div>
                    <div>
                        49°17'55.9"N 25°41'14.4"E
                    </div>
                </div>
            </div>
        )
    }
}

export default Text;