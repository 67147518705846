import React, { Component} from 'react';
import Text from './text';

class TerebovliaCastle extends Component {
    render () {
        return (
            <div>
                <Text />
            </div>
        );
    }
}

export default TerebovliaCastle;