import React, { Component} from 'react';
import churchoftheresurrectionofchrist1 from './churchoftheresurrectionofchrist1.jpg';
import churchoftheresurrectionofchrist2 from './churchoftheresurrectionofchrist2.jpg';
import churchoftheresurrectionofchrist3 from './churchoftheresurrectionofchrist3.jpg';
import churchoftheresurrectionofchrist4 from './churchoftheresurrectionofchrist4.jpg';
import churchoftheresurrectionofchrist5 from './churchoftheresurrectionofchrist5.jpg';
import churchoftheresurrectionofchrist6 from './churchoftheresurrectionofchrist6.jpg';



class ChurchOfTheResurrectionOfChrist extends Component{
    render(){
        return(
            <div>
                <div>
                    Локація №6 : Церква Воскресіння Христового
                </div>
                <div>
                    Монументальною спорудою центральної частини міста є церква Воскресіння Христового — пам'ятка культурної 
                    спадщини ХVІІІ ст. Найдавніша писемна згадка про храм міститься у книзі Василя Лаби "Літопис парафії 
                    Христового Воскресіння у м. Збараж від найдавніших часів до 1939 року". Початком будівництва мурованої 
                    Шевської (Воскресенської) церкви вважають 1760-й рік (старанням міщанина-швеця Григорія Гимонюка), а 
                    завершенням — 1764 р. (і приписують Миколі Василю Потоцькому, старості канівському).
                </div>
                <div>
                    <img className='img_content' src={churchoftheresurrectionofchrist1} />
                </div>
                <div>
                    Василь Лаба у своєму дослідженні ставить під сумнів інформацію про те, що церкву Воскресіння Христового у 
                    Збаражі перед 1764 р. завершував Микола Потоцький. Мовляв, працівники бібліотеки почерпнули інформацію у 
                    "місцевого історика О. Мандрика", а він "це МІГ взяти зі спогадів уродженця Збаража - В. Чернявського, який 
                    так подав до видання "Збаражчина". Останній "користувався 10-ма історичними дослідженнями загального характеру, 
                    родинними переказами та власними спогадами".
                </div>
                <div>
                    <img className='img_content' src={churchoftheresurrectionofchrist2} />
                </div>
                <div>
                    <ul>
                        Цікавинки:
                        <li>
                            1928 р., де йдеться, що церква збудована 1764 р. з теребовлянського каменю коштом громади та при допомозі 
                            воєводи Францішка Потоцького та його сина Станіслава. 
                        </li>
                        <li>
                            У 40-60 рр. ХVІІІ ст. ґрунтовно перебудовується костел та монастир оо. Бернардинів, Воскресенська та Успенська церква, відновлюється синагога
                        </li>
                        <li>
                            У ХVІІІ ст. в Збаражі існували німецька школа, єврейська школа, "школи для дяків" . Одна з них виникла в кін. ХVІІ ст. пр. церкві 
                            Спас на Залужжі, але коли останню було передано уніатам , то її перенесли до Збаража і вона відносилася вже до Воскресенської церкви.
                        </li>
                        <li>
                            В 1896 р. в місті виникла пожежа. Серед її жертв, поряд з іншими будівлями, опинилася Воскресенська церква та замок.
                        </li>
                    </ul>
                </div>
                <div>
                    <img className='img_content' src={churchoftheresurrectionofchrist4} />
                </div>
                <div>
                    Що стосується пожежі, відображеній на відмому фото кін. ХІХ ст., то  інформація, подана в "Духовних святинях Збаражчини", що вона (пожежа) 
                    відбулася в 1887 р., вимагає спростування. За В. Лабою, фото пожежі куполу церкви Воскресіння (нібито, 1887 р.) з риштуванням навколо центрального 
                    фасаду, зроблено не раніше 1899 р., оскільки, ремонт (фактично, заміна) даху тривав 1899-1902 рр. (коли було отримано 16000 К під заставу 26 моргів 
                    поля у Банку Крайового).
                </div>
                <div>
                    <img className='img_content' src={churchoftheresurrectionofchrist6} />
                </div>
                <div>
                    В 1933 р. о. Боліновський проводить у церкві ремонтно-реставраційні роботи: проведено електричне освітлення, зроблено нову мозаїчну підлогу 
                    (плитка привезена з Чехії), відновлена огорожа. У 1960 році церква була закрита і з неї зробили шкільний склад. У 1977 році зруйновано дзвіницю. 
                    Лише в 1989 році Збаразький райвиконком повернув церкву віруючим і розпочалася реставрація. З листопада 1989 року церква заново була освячена 
                    отцем Романом Водяним, а реставрація продовжувалась до 1990 року. У 1991 році відновлена церковна дзвіниця при в’їздній брамі, а в 1993 році на 
                    території церкви збудована невелика дерев’яна каплиця. У 1989 році Митрополит Володимир Стернюк призначає із Львова отців редемтористів для опікування 
                    місцевим храмом
                </div>
                <div>
                    <img className='img_content' src={churchoftheresurrectionofchrist3} />
                </div>
                <div>
                    У 1990 році до Збаража приїхав о. Йосип Янішевський. У 1991 році відновлення храму благословляє кардинал Любачівський, владики М. Гринчишин, 
                    М. Сабрига. З 1992 сотрудником у Воскресенську церкву прийшов о. Олег Юрик, у 1993 – деканом і парохом о. Григорій Еднорович. Отець Григорій 
                    відправляв служби у храмі разом із о. Омеляном Колодчаком, а згодом його (о. Григорія) замінив о. Олексій Боднарчук. Саме за старання останнього 
                    і побачила світ цитована книга В. Лаби. На сучасному етапі Збаразький деканат нараховує 28 церков, 12 священиків та 1 тисячу дворів греко-католиків.
                </div>
                <div>
                    <img className='img_content' src={churchoftheresurrectionofchrist5} />
                </div>
                <div>
                    А наступна з вами локація - це будинок де в свій час зупинявся Іван Франко.
                </div>
                <div>
                    <div>
                        Координати:
                    </div>
                    <div>
                        49.667725, 25.777032
                    </div>
                </div>
            </div>
        )
    }
}

export default ChurchOfTheResurrectionOfChrist;