import React, { Component} from 'react';
import trinitarian1 from './trinitarian1.jpg';
import trinitarian2 from './trinitarian2.jpg';
import trinitarian3 from './trinitarian3.jpg';

class ChurchOfTheAssumption extends Component{
    render(){
        return(
            <div>
                <div>
                    Локація №4 : успенська церква
                </div>
                <div>
                    Свято-Успенська церква, також Церква Успіння Пресвятої Богородиці — колишній Тринітарський костел-монастир у Збаражі, збудований у 1755—1758 рр.
                </div>
                <div>
                    <img className='img_content' src={trinitarian1} />
                </div>
                <div>
                    Тринітарії - «Орден Пресвятої Трійці» - католицький орден жебручих ченців. Заснований в 1198 р. французьким богословом Жаном де Мата 
                    й пустельником Феліксом де Валуа (Валезієм) для викупу полонених християн з мусульманської неволі. Девізом ордена стала фраза (лат.) 
                    «Gloria Tibi Trinitas et captivis libertas»  - дослівно перекладається як «Слава Тобі Трійця, а полоненим - свобода». Герб тринітаріїв 
                    - рівносторонній хрест з ланцюгами та кайданами - символізуює порятунок бранців з неволі.
                </div>
                <div>
                    <img className='img_content' src={trinitarian2} />
                </div>
                <div>
                    Практично, відсутня в джерелах інформація про тринітаріїв у Збаражі. Окремі розвідки лаконічно пов'язують цей орден з Юзефом графом Потоцьким, 
                    який, як відомо, де юре вступив у права на Збараж після 1695 р., а де факто – після 1700 р. Перші тринітарії-іспанці реформати на території РП 
                    з'явились після 1685 року. Впродовж XVI–XVIII ст. діяло 19 осередків Ордену, які містилися у Берестечку, ЗБАРАЖІ, Зінькові, Кам’янці Подільському 
                    та ін містах. Монахи цього ордену залишили Збараж вже у 1783 р., тому парафіяни, відомої, як мінімум з 1700 р., церкви Успіння Пресвятої Богородиці, 
                    могли або придбати будівлю, або вона була передана вже за Вінцентій Потоцького. Навіть не архітектору зрозуміло, що стиль тринітарських храмів у 
                    Кам'янці і Збаражі, майже, ідентичні, або дуже подібні! Але, думаю, що "наш" старший, хоча офіційно (за І. Прашком) датується 1758 роком. 
                </div>
                <div>
                    <img className='img_content' src={trinitarian3} />
                </div>
                <div>
                    <ul>
                        Ось деякі факти про Тринітаріїв:
                        <li>
                            Орден отримав назву «de la Trinite» і на початку мав дуже суворий статут, який забороняв їсти в звичайний час м'ясо і рибу і т.д., а 
                            також їздити на конях, володіти власністю
                        </li>
                        <li>
                            В народі їх звали «братами ослів» або «ослячим орденом» (ordo asinorum), оскільки вони пересувалися виключно на ослах і мулах.
                        </li>
                        <li>
                             Засоби для викупу тринітарії, головним чином, добували збором милостині. Непоодинокими були випадки, коли тринітарії віддавали 
                             себе самих у рабство за звільнення бранців.
                        </li>
                    </ul>
                </div>
                <div>
                    Тепер вирушаємо до єврейського кагалу. Там ви і знайдете наступний QR-код:
                </div>
                <div>
                    <div>
                        Координати:
                    </div>
                    <div>
                        49.665486, 25.777527
                    </div>
                </div>
            </div>
        )
    }
}

export default ChurchOfTheAssumption;