import React, { Component} from 'react';
import { Link } from 'react-router-dom';

class ZbarazhArtFest extends Component{
    render(){
        return(
            <div>
                <div className='zbarazhartfest_header'>
                    Програма фестивалю ЗбаражArtFest спільно з проєктом "Пізнай Своє"
                </div>
                <div>
                    <div>
                        Фестиваль відбуватиметься з 10 по 21 серпня. Під час фестивалю ви можете долучитися до різного виду активностей (майстеркласи, тренінги), а також доєднатися до 
                        свторення муралів спільно з командою "Пізнай своє". Заключна частина фестивалю та відкриття муралів відбудуться 22 серпня.
                    </div>
                    <div>
                        За детальню інформацією можете звертатися на сторінку 
                        <a href='https://www.facebook.com/%D0%96%D0%B8%D0%B2%D0%B0-%D0%A1%D1%86%D0%B5%D0%BD%D0%B0-101713244747103/?ref=bookmarks'> ЗбаражArtFest </a>
                        та сторінку проєкту 
                        <a href='https://www.instagram.com/piznaysvoye/'> "Пізнай Своє" </a>
                         у інстаграмі. 
                    </div>
                    <div>
                        У розкладі можливі зміни. Слідкуйте за анонсами (:
                    </div>
                </div>
                <ul>
                    <li>
                        10.08 Понеділок:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - Вечір знайомства з учасниками проєкту "Пізнай Своє" (на острівку біля озера)
                            </li>
                        </ul>
                    </li>
                    <li>
                        11.08 Вівторок:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - Танці з назаром, танцювальний флешмоб (міський стадіон)
                            </li>
                        </ul>
                    </li>
                    <li>
                        12.08 Середа:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - На вибір: "Малювання на піску", створення еко квіток (піцерія UNO) (Очікуйте посилання на реєстрацію)
                            </li>
                        </ul>
                    </li>
                    <li>
                        13.08 Четвер:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - salsa party (міський стадіон)
                            </li>
                        </ul>
                    </li>
                    <li>
                        14.08 П'ятниця:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - Презентація проєкту "Пізнай Своє", волонтерської програми "Будуємо Україні Разом", спільний перегляд фільму (очікуйте анонсу)
                            </li>
                        </ul>
                    </li>
                    <li>
                        15.08 Субота:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - Очікуйте анонсу
                            </li>
                        </ul>
                    </li>
                    <li>
                        16.08 Неділя:
                        <ul>
                            <li>
                                Time off
                            </li>
                        </ul>
                    </li>
                    <li>
                        17.08 Понеділок:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - Воркшоп "Емоційний інтелект vs IQ" (очікуйте)
                            </li>
                        </ul>
                    </li>
                    <li>
                        18.08 Вівторок:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - танці з Назаром Street Dance (міський стадіон)
                            </li>
                        </ul>
                    </li>
                    <li>
                        19.08 Середа:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - Малювання на мольбертах (очікуйте реєстраційної анкети, кількість місць обмежена 10 осіб)
                            </li>
                        </ul>
                    </li>
                    <li>
                        20.08 Четвер:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - reggeaton party (міський стадіон)
                            </li>
                        </ul>
                    </li>
                    <li>
                        21.08 П'ятниця:
                        <ul>
                            <li>
                                9:00-18:00 - Створення муралів
                            </li>
                            <li>
                                20:00 - Sport time (міський стадіон)
                            </li>
                        </ul>
                    </li>
                    <li>
                        22.08 Субота:
                        <ul>
                            <li>
                                12:00 - Відкриття муралів
                            </li>
                            <li>
                                22:00 - Ватра (на острівку біля озера)
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}

export default ZbarazhArtFest;