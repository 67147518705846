import React, { Component} from 'react';
import bernardinemonastery1 from './bernardinemonastery1.jpg';
import bernardinemonastery2 from './bernardinemonastery2.jpg';
import bernardinemonastery3 from './bernardinemonastery3.jpg';
import bernardinemonastery4 from './bernardinemonastery4.jpg';
import bernardinemonastery5 from './bernardinemonastery5.jpg';


class BernardineMonastery extends Component{
    render(){
        return(
            <div>
                <div>
                    Локація №3 : Монастир Бернардинів (церква св Антонія)
                </div>
                <div>
                    Хто такі бернардини?
                </div>
                <div>
                    Орден бере свій початок в 1209 року, коли заснував св. Франциск Ассизький 
                    заснував орден Орден Братів Менших (OFM – Ordo Fatum Minorun), звідси інша, поширена назва – 
                    францисканц. Статутна вимога бідності відносилася у францисканців до Ордену в цілому. Францисканці 
                    жили не в закритих монастирях, а між людьми, мандрували, проповідували на мові простого народу бідність 
                    і аскетизм, займалися добродійністю.
                </div>
                <div>
                    XV ст. францисканці розгорнули місіонерську діяльність у Польщі. Тут вони назвалися бернардинцями на честь св. 
                    Бернардина з м. Сієна (Італія), іменем якого було названо перший францисканський костел у Кракові (1453). Звідси 
                    і пішла назва бернардинці, яка була поширена на теренах Речі Посполитої.
                </div>
                <div>
                    Комплекс споруд Монастиря Бернардинів
                </div>
                <div>
                    До складу комплексу входять, по суті, три окремих споруди: костел Св. Антонія, келії (тобто, власне, монастир) та 
                    дзвіниця. Ідея спровадження, популярного в той час ордену оо. Бернардинів до Збаража, в знак вдячності за їх участь 
                    в якості капеланів у військах кн. Збаразьких, належала ще Янушу Миколайовичу на початку ХVІІ ст. Реалізацію задуму 
                    батька розпочав його син кн. Юрій у 1627 р., виділивши в замку Нового Збаража приміщення під каплицю.
                </div>
                <div>
                    <img className='img_content' src={bernardinemonastery1} />
                </div>
                <div>
                    Спорудження «першого» костелу у Новому Збаражі розпочалося навесні 1628 року біля підніжжя невеликого пагорба поблизу р. 
                    Гнізна. Дещо підмокла територія (не болото(!)) сприяла будівничим в копанні глибоких фундаментів святині, в підземеллях 
                    якої було передбачено крипти для померлих. В лютому 1631 р. був отруєний і помер фундатор святині – Юрій кн. Збаразький. 
                    Проте це не зупинило роботи. Стараннями князя Януша Вишнивецького в 1635 основні будівельні роботи були виконані, а ще 
                    через два роки зовнішні роботи були завершені. Храм одаронавий, 19х10 м трьома вівтарями (центральний священномученика 
                    Юрія, лівий – Божої Матері, правий – святого Антонія з Падуї).
                </div>
                <div>
                    <img className='img_content' src={bernardinemonastery2} />
                </div>
                <div>
                    На той час існувало два костели бернардинів: монастирський (у Новому Збаражі) та парафіяльний (у Старому Збаражі). Але 
                    у 1648 р. парафіяльний костел у Старому Збаражі було знищено, а у 1675 р. турки, татари і козаки знищили повністю монастирський 
                    костел, було врятовано лише три згадані вівтарні образи.
                </div>
                <div>
                    У 1726-1732 рр. стараннями Юзефа Потоцького, який дав на будівництво 20 000 злотих, був збудований так званий “парафіяльний костел”, 
                    що займав місце навпроти комплексу бернардинського конвету на, так званій, Парафіяльній (фарній) площі, частина якої була цвинтарем. 
                    Його спорудження було пов’язане з переносом римо-католицької громади зі Старого Збаража до Нового у 1726 р. Цей парафіяльний костел 
                    проіснував до 1789 року, коли став жертвою великої пожежі у Збаражі. Перша пожежа 1789 р. виникла 17 червня в будинку якогось убогого 
                    міщанина і швидко перекинулася на інші споруди. Загорівся дах на Успенській церкві, загорівся Парафіяльний костел та шпиталь, а згодом 
                    костел і монастир Бернардинів. Вогонь не вщухав до вечора і знищив велику кількість будинків. 19 червня 1789 р. вибухнула ще одна пожежа, 
                    яка знищила 64 будинки на Залозецькому передмісті, але до центру її не допустили.
                </div>
                <div>
                    <img className='img_content' src={bernardinemonastery3} />
                </div>
                <div>
                    Лише в 1746 р. за ініціативи Станіслава і Вікторії Потоцьких розпочато спорудження “другого” бернардинського костелу за проєктом і від 
                    контролем сілезького архітектора Антонія Ганца. Розпочалися роботи із розібрання руїн костельних мурів і фундаментів (зберігши тільки крипту) 
                    і копання нових фундаментів та підземелля під цілою майбутнею святинею для крипт.
                </div>
                <div>
                    В 1753 році в правій вежі було влаштовано годинник (за 1800 злотих роботи виконав львівський годинникар Jan Schoedt), що відбивав години і чверті. 
                    В лівій вежі поміщено два дзвони, на одному з яких був напис “Rok 1713”, а на другому “ Do modłów czule zapraszam i pokój odłaszam Roku Pańskiego 
                    1732”. Тут також були два менші дзвони з першого костелу. 1754р підлогу вимощено мармуровою плиткою білого і чорного кольорів, привезеною з Теребовлі 
                    і отиньковано весь костел. Тоді ж було обнесено костельно-монастриський комплекс високим оборонним муром з стрільницями та двома брамами.
                </div>
                <div>
                    <img className='img_content' src={bernardinemonastery5} />
                </div>
                <div>
                    Цікавою є інформація Ю. Кахеля, що законний костел був „певному сенсі копією фарної святині з тою ж самою кількістю вівтарів, присвячені тим самим 
                    святим, ті самі фрески на аркадах“ тощо. У приміщенні монастиря в середині ХІХ ст. розміщувалися крім келій і бібліотеки, ще й початкова школа, законна 
                    і народна гімназії, казино і офіси повітового уряду, міська.
                </div>
                <div>
                    А наша наступна локація - Успенська церква або Тринітарський костел
                </div>
                <div>
                    <div>
                        Координати:
                    </div>
                    <div>
                        49.664489, 25.777096
                    </div>
                </div>
            </div>
        )
    }
}

export default BernardineMonastery;