import React, { Component} from 'react';
import sobolivka from './sobolivka.jpg';
import sokil1 from './sokil1.jpg';
import sokil2 from './sokil2.jpg';
import countyadministrations1 from './countyadministrations1.jpg';
import countyadministrations2 from './countyadministrations2.jpg';
import bristol from './bristol.jpg';
import frenkelsham from './frenkelsham.jpg';

 
class CityHall extends Component{
    render(){
        return(
            <div>
                <div>
                    Локація №2 : Міська Рада
                </div>
                <div>
                    Спробуймо перенестись в епоху ХХ ст.  і уявити, що знаходимось у передмісті бурґу, у новозбудованому центрі так «польської дільниці». 
                    Подивімось на право. Бачите двоповерхову видовжену з обох боків будівлю? Це відома мешканцям міста «Соболівка», що трансформувалась в кінці 
                    минулого століття у, так звану, «Нижню чайну».  Один з депутатів бурґу  п. Вацлав Соболь з власної ініціативи і власним коштом будує у Збаражі 
                    новий осередок польської торгівлі, щоб навколо нього зосередити все господарче життя бургу. Будову проводить на власних парцелях, розміщених в центрі 
                    міста, поблизу всіх урядових установ та управління повіту. «Соболівка», або  комплекс торгівельних блоків була призначена для розміщення переважно 
                    польських і українських склепів та магазинів. Тут розмістились 12 фронтальних склепів із 5 тильними убікаціями (вбиральнями), зі сторони подвір’я − 
                    10 менших склепів, 5 тильних убікацій для фронтальних склепів, готель, помешкання для купців, магазини збіжжя та сільськогосподарського реманенту, 
                    продуктові склади. На фото 20-х років ХХ ст. зафіксовано процес будівництва торгівельного комплексу «Соболівка»
                </div>
                <div>
                    <img className='img_content' src={sobolivka} />
                </div>
                <div>
                    Одразу за «Соболівкою», проти годинникової стрілки, знаходиться будинок польського спортивно-гімнастичного товариства „Сокіл“ („Sokół“). Будівля 
                    споруджена у 1910 році у вигляді П-подібної двоповерхової гарно оздобленої будівлі у стилістиці модерн. Тут передбачили  актові, спортивні та 
                    іншими зали та кімнати, де функціонували різноманітні громадські об'єднання та установи. Тут відбувалися різні урочистості, театральні вистави, 
                    виступав духовий і смичковий оркестри. Крім того, тут розташовувалися Товариство друзів стрілецького союзу, Інспекція сільськогосподарської 
                    підготовки, Союз громадської праці для жінок, Туристично-екскурсійне товариство Поділля, Союз офіцерів та офіційних службовців польського заповідника, 
                    Офіційна родина, Легіон молодих, Червоний Хрест, Родина поліції, Коло друзів-скаутів та ін. 
                </div>
                <div>
                    <img className='img_content' src={sokil1} />
                </div>
                <div>
                    У 1916 р., під час першої світової війни, тут 
                    знаходився штаб та госпіталь бельгійського бронедивізіону АСМ (Autos-Canons-Mitrailleuses), а з 1944 р. −  штаб однієї з радянських військових 
                    частин. Згодом тут відкривається Будинок культури. Після реконструкції та добудови у 1963-64 рр. в будинку розміщуються актовий зал на 400 місць, 
                    просторе фойє, діють хорові гуртки, танцювальний ансамбль, естрадний та духовий оркестри, самодіяльний драматичний театр тощо. У 1985 р. актовий 
                    зал перенесено до культурно-спортивного комплексу у Збаразькому замку, а в БК відкрито дискотеку. Зараз в будинку функціонують  підрозділи відділу 
                    культури, туризму та фандрейзингу Збаразької РДА.
                </div>
                <div>
                    <img className='img_content' src={sokil2} />
                </div>
                <div>
                    Рухаємось далі проти годинникової стрілки, і бачимо перед собою будівлю Збаразької міської  ради. Одразу за нею, на пагорбі – двоповерховий будинок, 
                    де з 2006 року функціонує Національний заповідник «Замки Тернопілля». Саме з цією будівлею познайомимось ближче.  Побудована у 1880-1891 рр. в стилі 
                    "українського модерну", є цінним прикладом адміністративної будівлі кінця ХІХ століття. 
                </div>
                <div>
                    <img className='img_content' src={countyadministrations1} />
                </div>
                <div>
                    Це одна з трьох двоповерхових будинків, призначених для адміністративних установ повіту. В будинках були розміщені повітове староство, 
                    міський суд, скарбовий уряд, повітовий відділ і повітова комунальна каса.
                </div>
                <div>
                    <img className='img_content' src={countyadministrations2} />
                </div>
                <div>
                    Спускаємось знову вниз, і зліва бачимо  триповерхову модернову будівлю з  нетиповими двома однаковими вежами з гостроверхими восьмигранними 
                    шпилями та шатровим дахом.  Це так званий „БРІСТОЛЬ“, який був побудований на зламі ХІХ-ХХ ст. на замовлення збаразького нотаріуса пана Волянського. 
                    Будівлю орендувала цікава пані Рудзікова та використовувала її як готельно-ресторанний комплекс над р. Гнізною. На першому поверсі знаходився ресторан 
                    з більярдною, на другому — готельні номери (при бажанні з відповідними інтимними послугами). За період існування комплексу, як свідчать історичні джерела, 
                    у ньому побували різноманітні громадсько-політичні діячі, письменники та музиканти. А у 1920 р. послугами „Брістоля“ скористалися червоні козаки Віталія 
                    Примакова, які не розплатившись, нахабно скинули в річку пам'ятник А. Міцкевичу, що стояв неподалік, та підірвали старий дерев'яни й Чорний міст. Споруда 
                    внесена до переліку пам'яток архітектури місцевого значення, як «Адміністративний будинок. Охоронний №132 М».
                </div>
                <div>
                    <img className='img_content' src={bristol} />
                </div>
                <div>
                    І останньою будівлею у цьому п’ятикнижжі пам’яток є Шинок Френкеля, побудований у 1930х роках поблизу бетонного варіанту «Чорного мосту“. 
                    В цьому будинку проживав Арон Френкель,, який займався роздрібною торгівлею алкогольними напоями та був власником шинка.
                </div>
                <div>
                    <img className='img_content' src={frenkelsham} />
                </div>
                <div>
                    Що ж вирушаємо далі. Наступна локація костел Бернардинів.
                </div>
                <div>
                    <div>
                        Координати:
                    </div>
                    <div>
                        49.663375, 25.777808
                    </div>
                </div>
            </div>
        )
    }
}

export default CityHall;