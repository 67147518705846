import React, { Component } from 'react';
import Text from './text'

class CityHall extends Component {
    render() {
        return(
            <div>
                <Text />
            </div>
        );
    }
}

export default CityHall;